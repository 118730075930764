/* HTML: <div class="loader"></div> */
.loader {
  width: 40px;
  aspect-ratio: 1;
  border-radius: 50%;
  background:
    radial-gradient(farthest-side,rgb(83, 131, 236) 94%,#0000),
    radial-gradient(farthest-side,rgb(216, 81, 64) 94%,#0000),
    radial-gradient(farthest-side,rgb(241, 191, 66) 94%,#0000),
    radial-gradient(farthest-side,rgb(88, 166, 92) 94%,#0000),
    #ED303C;
  background-size: 105% 105%;
  background-repeat: no-repeat;
  animation: l5 2s linear infinite; 
}
@keyframes l5 {
  0%, 
  100%  {background-position: 50% -50px,-40px 50%, 50% calc(100% + 50px),calc(100% + 50px) 50%}
  20%,
  25% {background-position: 50% -50px,-50px 50%, 50% calc(100% + 50px),50% 50%}
  40%,
  45% {background-position: 50% -50px,-50px 50%, 50% 50%,50% 50%}
  60%,
  65% {background-position: 50% -50px, 50%  50%, 50% 50%,50% 50%}
  80%,
  85%{background-position: 50%  50% , 50%  50%, 50% 50%,50% 50%}
}