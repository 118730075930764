@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'SourceHanSansCN';
  src: url('./font//SourceHanSansSC.woff2') format('opentype');
}
@font-face {
  font-family: 'SF';
  src: url('../assets//font//sf-pro-icons_regular.woff2') format('woff2');
}
@font-face {
  font-family: 'Mono';
  src: url('../assets/font/RobotoMono-Medium.ttf') format('ttf');
}

@font-face {
  font-family: 'FiraCode';
  src: url('./font/FiraCode-Regular.woff2') format('woff2');
}

html {
  font-family: 'SourceHanSansCN', system-ui, -apple-system, PingFang SC,
    'Microsoft YaHei', Segoe UI, Roboto, Helvetica, noto sans sc,
    hiragino sans gb, 'sans-serif', Apple Color Emoji, Segoe UI Emoji,
    Not Color Emoji;
}
/* 设置代码的字体 */
.intro {
  font-family: 'SF', system-ui;
}
body {
  @apply bg-[#FCFCFC];
}
pre {
  font-family: 'FiraCode', system-ui, -apple-system;
}

:root {
  --bg-dark-theme-color: #2d373c;
}
/* 定义滑块的宽度 */
::-webkit-scrollbar {
  width: 5px;
  height: 10%;
}
/* 定义滚动条轨道的样式 */
::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* 设置轨道背景色 */
}

/* 定义滚动条滑块的样式 */
::-webkit-scrollbar-thumb {
  background-color: #888; /* 设置滑块背景色 */
  border-radius: 5px; /* 设置滑块圆角 */
}

/* 鼠标悬停在滚动条上时的样式 */
::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* 设置滑块背景色 */
}

.hljs {
  @apply dark:!bg-[#1e293b] !bg-[#F2F5F7] !text-[black] dark:!text-[#ffffff];
}
/* 设置行内代码块样式 */
p code {
  @apply dark:bg-[#0284c7];
  background-color: #bae6fd;
  display: inline-block;
  border-radius: 4px;
  padding: 0.5px 2px;
  margin: 0 2px;
}
p code::before,
p code::after {
  content: '';
  display: none;
  height: 0;
}

/* 设置代码块左侧样式 */
pre {
  position: relative;
  border-left: 5px solid #b9ccdb;
}
pre::after {
  content: attr(data-after-content);
  position: absolute;
  right: 10px;
  top: 5px;
  display: inline-block;
  color: #3559e0;
  font-size: clamp(12px 0.4em 16px);
}

/* 渐变按钮 friendlinks */
.btn-grad {
  background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0.15) 0%,
      rgba(0, 0, 0, 0.15) 100%
    ),
    radial-gradient(
        at top center,
        rgba(255, 255, 255, 0.4) 0%,
        rgba(0, 0, 0, 0.4) 120%
      )
      #989898;
  background-blend-mode: multiply, multiply;
  padding: 15px 45px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  box-shadow: 0 0 20px #eee;
  border-radius: 10px;
  display: block;
}

.btn-grad:hover {
  user-select: none;
  cursor: pointer;
  background-position: right center;
  text-decoration: none;
}

/* 配置animate.css延迟时间 */
.animate__animated.animate__jackInTheBox {
  --animate-duration: 1s;
}

/* 气泡样式 messagestack */
.bubble {
  width: 200px;
  height: 60px;
  line-height: 60px;
  text-align: left;
  padding-left: 10px;
  border-radius: 20px;
  user-select: none;
  position: absolute;
  cursor: pointer;
}
.bubble::before {
  display: block;
  position: absolute;
  left: 20px;
  bottom: -18px;
  content: '';
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid var(--before-color);
  /* 伪元素可以访问所在具体元素上的css变量 */
  border-bottom: 10px solid transparent;
}
